<script setup>
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits(['update:showModal'])
const active = ref(false)

watch(
  () => props.showModal,
  (value) => {
    active.value = value
  },
)
</script>

<template>
  <el-dialog v-model="active" title="Warning" width="500" center>
    <span>If your payment is pertaining to searches, select 'searches'. Otherwise, select 'other'</span>
    <template #footer>
      <div class="dialog-footer">
        <a href="/payment" class="mr-6"><el-button type="primary" @click="$emit('update:showModal', false)">Searches</el-button></a>
        <a href="/payment-other"><el-button type="primary" @click="$emit('update:showModal', false)">Other</el-button></a>
      </div>
    </template>
  </el-dialog>
</template>
